import React, { useEffect, useState } from "react";
import "./LocationPage.css";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../components/Back/Back";
import P1 from "../../assets/images/property/p1.jpeg";
import Accordioning from "../../components/Accordioning/Accordioning";
import { SidebarData, Locations, AqMode } from "../../assets/SideData/SideData";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "../../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import { db } from "../../assets/lib/Firebase/Firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import Loading from "../../BusinessPage/Loading/Loading";

function LocationsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState("");
  const [auctiontype, setAuctionType] = useState("Rent");
  useEffect(() => {
    setLoading(true);
    // setAds(cars);
    setLoading(false);
  }, []);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,

      where("regionUrl", "==", id),
      where("active", "==", true)
      // //orderBy("createdAt", "desc"),
      // limit(8)
    );
    onSnapshot(q, (snapshoot) => {
      setResult(
        snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, [id]);

  console.log(result);
  return (
    <>
      <Navbar />
      <div>
        <Back
          name={id.replaceAll("_", " ")}
          title={`See  Our ${id.replaceAll("_", " ")}`}
        />
        <div className="levbitz_container">
          <div className="row">
            <div className="col l9">
              {loading ? (
                <Loading />
              ) : result && result.length > 0 ? (
                result
                  .filter((item) => {
                    if (property === "") {
                      return item;
                    } else {
                      return (
                        item.data.category === property
                        //&&
                        // item.data.acquisition === auctiontype
                      );
                    }
                  })
                  .map((item) => {
                    return (
                      <SingleProperty
                        url={item.id}
                        bgImage={
                          item.data.images && item.data.images.length > 0
                            ? item.data.images[0]
                            : P1
                        }
                        text={item.data.title}
                        region={item.data.region}
                        acquisition={item.data.acquisition}
                      />
                    );
                  })
              ) : (
                <>
                  <h2>No Data</h2>
                </>
              )}
            </div>

            <div className="col l3">
              <div
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                }}
                className="white"
              >
                <div className="row">
                  <div className="col l4"></div>
                  <div className="col l8">
                    <div>
                      <button
                        style={{
                          background: "red !important",
                        }}
                        onClick={() => {
                          // setAuctionType("");
                          setProperty("");
                        }}
                        className="btn btn-small red"
                      >
                        clear filter
                      </button>
                    </div>
                  </div>
                </div>

                <Accordioning title={"Filters"}>
                  <div>
                    <div
                      style={{
                        marginTop: "2rem",
                      }}
                      className="col l12 s12"
                    >
                      <select
                        onChange={(e) => setProperty(e.target.value)}
                        // disabled={isSubmenuOpen === false}
                        className="browser-default  fabian_multiple_select"
                      >
                        <option disabled selected>
                          Choose Category
                        </option>

                        {SidebarData.map((mode) => {
                          return (
                            <option
                              key={mode.categoryUrl}
                              value={mode.categoryUrl}
                            >
                              {mode.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/*<div>
                    <div
                      style={{
                        marginTop: "2rem",
                      }}
                      className="col l12 s12"
                    >
                      <select
                        onChange={(e) => setAuctionType(e.target.value)}
                        // disabled={isSubmenuOpen === false}
                        className="browser-default  fabian_multiple_select"
                      >
                        <option disabled selected>
                          Acqustion Mode
                        </option>

                        {AqMode.map((mode) => {
                          return (
                            <option key={mode} value={mode}>
                              {mode}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                      </div>*/}
                </Accordioning>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationsPage;

const SingleProperty = ({ bgImage, text, region, acquisition, url }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Link to={`/lead/${url}`}>
        <div data-aos="fade-up" className="col l4 s12">
          <div className="xyz_levbitz">
            <div
              style={{
                backgroundImage: `url(${bgImage})`,
              }}
              className="single_property_wrap"
            >
              <div className="row">
                <div className="col l4 s5">
                  <div>
                    <span className="sales">For {acquisition}</span>
                  </div>
                </div>
                <div className="col l8  s7">
                  <div>
                    <span className="location center">{region}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_property_content">
              <h6>{text}</h6>

              <p>Price on call</p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
