import React from "react";
import { featured } from "../../../assets/data/Data";
import { Link } from "react-router-dom";

const FeaturedCard = () => {
  return (
    <>
      <div className="content grid4 mtop">
        {featured.map((items, index) => (
          <Link to={items.path}>
            <div className="box" key={index}>
              <img src={items.cover} alt="" />
              <h6>{items.name}</h6>
              {/*<label>{items.total}</label>*/}
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default FeaturedCard;
