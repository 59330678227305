import React, { useState } from "react";
import Heading from "../../common/Heading/Heading";
import "./hero.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [region, setRegion] = useState("");
  const [property, setProperty] = useState("");

  const invalid = region === "" || property === "";
  const queryHandler = () => {
    if (region === "" || property === "") {
      alert("Please enter a region and a property");
    } else {
      navigate(`query/${region}/${property}`);
    }
  };
  return (
    <>
      <section className="hero">
        <div className="container">
          <Heading
            title="Search Your Next Home "
            subtitle="Find new & featured property located in your local city."
          />

          <div className="white hero_input_wrap">
            <div className="row">
              <div className="col l5 s6">
                <select
                  onChange={(e) => setRegion(e.target.value)}
                  className="browser-default  fabian_multiple_select col "
                >
                  <option disabled selected>
                    Choose Region
                  </option>
                  <option value="bangalore_north">Bangalore North</option>
                  <option value="bangalore_east">Bangalore East</option>
                  <option value="bangalore_west">Bangalore West</option>
                  <option value="bangalore_south">Bangalore South</option>
                </select>
              </div>
              <div className="col l5 s6">
                <select
                  onChange={(e) => setProperty(e.target.value)}
                  className="browser-default  fabian_multiple_select"
                >
                  <option disabled selected>
                    Choose Property
                  </option>

                  <option value="residential_property">
                    Residential Property
                  </option>
                  <option value="commerical_property">
                    Commerical Property
                  </option>
                  <option value="layouts">Layouts</option>
                  <option value="plots">Plots</option>
                  <option value="apartments">Apartments</option>
                  <option value="rentals">Rentals</option>
                  <option value="pg">PG</option>
                  <option value="warehouse">Warehouse</option>
                </select>
              </div>
              {/*<div className="box">
              <span>Price Range</span>
              <input type="text" placeholder="Price Range" />
            </div>
            <div className="box">
              <h6>Advance Filter</h6>
  </div>*/}
              <div className="col l2 s12 center">
                <button
                  onClick={queryHandler}
                  className="btn amber darken-3 z-depth-0 "
                >
                  <i className="fa fa-search"></i> search
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
