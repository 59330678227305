import React, { useState, useEffect } from "react";
import Heading from "../../common/Heading/Heading";
import "./recent.css";
import RecentCard from "./RecentCard";
import { db } from "../../../assets/lib/Firebase/Firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import Loading from "../../../BusinessPage/Loading/Loading";

const Recent = () => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    // setAds(cars);
    setLoading(false);
  }, []);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,

      where("active", "==", true)
      // //orderBy("createdAt", "desc"),
      // limit(8)
    );
    onSnapshot(q, (snapshoot) => {
      setResult(
        snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, []);

  console.log(result);
  return (
    <>
      <section className="recent_wrap">
        <div className="">
          <Heading
            title="Recent Property Listed"
            // subtitle="Over the few decades, Bangalore has emerged as an IT hub of India, thus attracting large number of industries to set up commercial real estate in Karnataka. Sudden upsurge in commercial real estate has also given a boost to the number of immigrants looking for affordable residential property"
          />
          <div className="row">
            {loading ? (
              <Loading />
            ) : (
              result.map((item) => {
                return <RecentCard item={item} key={item.id} />;
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Recent;
