import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";
const Logo =
  "https://static.vecteezy.com/system/resources/thumbnails/005/327/854/small/white-house-model-in-blue-background-for-refinance-plan-and-real-estate-concept-free-photo.jpg";

const RecentCard = ({ item }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { images, title, address, acquisition } = item.data;

  const [placeholderImg, setPlaceholderImg] = useState(Logo);
  useEffect(() => {
    if (images && images.length > 0) {
      setPlaceholderImg(item.data.images[0]);
    } else {
      setPlaceholderImg(Logo);
    }
  }, [item.data.images, images]);

  console.log(item.data);
  return (
    <>
      <div className="  col l3 s12">
        <div
          data-aos="zoom-in"
          style={{
            marginBottom: "30px",
            borderRadius: "10px",
          }}
          className="single_recent_card"
        >
          <Link to={`/lead/${item.id}`}>
            <div className="img">
              <img
                className="responsive-img"
                src={placeholderImg}
                alt=""
                style={{
                  borderRadius: 10,
                  height: 180,
                  objectFit: "cover",
                }}
              />
            </div>
          </Link>
          <div
            style={{
              padding: "10px 10px",
            }}
            className="text"
          >
            <div className="category flex">
              <span
                style={{
                  background:
                    acquisition === "Sale" ? "#25b5791a" : "#ff98001a",
                  color: acquisition === "Sale" ? "#25b579" : "#ff9800",
                }}
              >
                For {acquisition}
              </span>
              <i className="fa fa-heart red-text"></i>
            </div>
            <p className="truncate">{title}</p>
            <p>
              <i className="fa fa-location-dot"></i> {address}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentCard;
