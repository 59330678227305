import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDOiuGj2cUuaqA5yeLLaVYOLnk6k66cT68",
  authDomain: "shanker-real-estate.firebaseapp.com",
  projectId: "shanker-real-estate",
  storageBucket: "shanker-real-estate.appspot.com",
  messagingSenderId: "487958340806",
  appId: "1:487958340806:web:3be8929f609fc1a3ca23c7",
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
