import React, { useState, useEffect, useRef } from "react";
import "./SingleLead.css";

import Navbar from "../../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import { db } from "../../assets/lib/Firebase/Firebase";
import { doc, getDoc } from "firebase/firestore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import emailjs from "@emailjs/browser";

function SingleLead() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [category, setCategory] = useState("");
  const [facilites, setFacilities] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [area, setArea] = useState("");

  const [email, setEmail] = useState("");

  useEffect(() => {
    const docRef = doc(db, "ads", id);
    getDoc(docRef).then((doc) => {
      console.log(doc.data());
      setCategory(doc.data().category);
      setFacilities(doc.data().facilities);
      setSubcategory(doc.data().subcategory);
      setDistrict(doc.data().district);
      setDivision(doc.data().division);
      setAddress(doc.data().address);
      setPhoneNumber(doc.data().phoneNumber);
      setPrice(doc.data().price);
      setTitle(doc.data().title);
      setDescription(doc.data().description);
      setEmail(doc.data().email);
      setImages(doc.data().images);
      setAddress(doc.data().address);
      setFacilities(doc.data().facilites);
      setArea(doc.data().area);
    });
    setLoading(false);
  }, [id]);

  const [showNum, setShowNum] = useState(false);
  const [showAdd, setShowAdd] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [queryMail, setQueryMail] = useState("");

  const invalid = name === "" || number === "" || queryMail === "";

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jeggi09",
        "template_ms0mtzc",
        form.current,
        "1KzusCeVjU5pVcNOk"
      )
      .then(
        (result) => {
          // console.log(result.text);
          alert("Message Submitted Successfully");
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  if (loading) {
    return <div>Loading...</div>;
  } else
    return (
      <>
        <Navbar />
        <div
          className="levbitz_single_car_wrap"
          style={{
            color: "#6A6F7B",
            background: "#f2f3f5",
          }}
        >
          <div
            style={{
              background: "#094c59",
              height: "60px",
            }}
          ></div>

          {showPopup ? (
            <ImagePoping setShowPopup={setShowPopup} images={images} />
          ) : null}
          <div className="container">
            <h5>{title}</h5>

            <div className="row">
              <div className="col l8">
                <div>
                  <div className="row">
                    {images ? (
                      <>
                        {images.slice(0, 1).map((imgUrl) => {
                          return (
                            <div key={imgUrl} className="col l6 s12 ">
                              <img
                                onClick={() => setShowPopup(true)}
                                src={imgUrl}
                                alt="img"
                                className="responsive-img"
                                style={{
                                  borderRadius: 10,
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : null}

                    {images ? (
                      <>
                        {images.slice(1, 2).map((imgUrl) => {
                          return (
                            <div key={imgUrl} className="col l6 s12 ">
                              <img
                                onClick={() => setShowPopup(true)}
                                src={imgUrl}
                                alt="img"
                                className="responsive-img"
                                style={{
                                  borderRadius: 10,
                                  opacity: 0.2,
                                  height: 170,
                                }}
                              />

                              <div
                                style={{
                                  marginTop: -150,
                                  marginLeft: "30%",
                                }}
                              >
                                <h3
                                  style={{
                                    fontSize: "2.5rem",
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {images.length - 5}+
                                </h3>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>

                  <div className="row">
                    {/*images ? (
                    <>
                      {images.slice(3, 5).map((imgUrl) => {
                        return (
                          <div key={imgUrl} className="col l4 s12 ">
                            <img
                              onClick={() => setShowPopup(true)}
                              src={imgUrl}
                              alt="img"
                              className="responsive-img"
                              style={{
                                borderRadius: 10,
                              }}
                            />
                          </div>
                        );
                      })}
                    </>
                    ) : null*/}

                    {/*images ? (
                    <>
                      {images.slice(1, 2).map((imgUrl) => {
                        return (
                          <div key={imgUrl} className="col l4 s12 ">
                            <img
                              onClick={() => setShowPopup(true)}
                              src={imgUrl}
                              alt="img"
                              className="responsive-img"
                              style={{
                                borderRadius: 10,
                                opacity: 0.2,
                              }}
                            />

                            <div
                              style={{
                                marginTop: -150,
                                marginLeft: "30%",
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: "2.5rem",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                {images.length - 5}+
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </>
                    ) : null*/}
                  </div>
                </div>

                <div className="white levbitz_single_car_overview">
                  <h3
                    style={{
                      marginTop: 40,
                    }}
                  >
                    About Property
                  </h3>

                  <hr />

                  <div className="row">
                    <div className="col l6">
                      <div>
                        <p>
                          {" "}
                          <span className="key">Category:</span> {category}
                        </p>
                        <p>
                          {" "}
                          <span className="key">Owner:</span>
                          Shankar Real Estate
                        </p>
                        <p>
                          {" "}
                          <span className="key">Adress:</span>
                          {address}
                        </p>
                        <p>
                          {" "}
                          <span className="key">Facilities:</span>
                          {facilites}
                        </p>
                        <p>
                          {" "}
                          <span className="key">Area:</span>
                          {area}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div>
                      <h5>Description</h5>

                      {!description
                        ? "details will be available soon"
                        : description
                            .toString()
                            .split(".")
                            .map((item, index) => {
                              return (
                                <p key={index} className="summaryTwo">
                                  {item}
                                </p>
                              );
                            })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col l4">
                <div className="white levbitz_single_car_name_and_price">
                  <h6>{title}</h6>

                  <p>
                    #21, 50ft. Main Road , NGEF Layout, Sanjay Nagar, Bengaluru-
                    560094
                  </p>
                  <div>
                    <span>+919845169306</span>
                  </div>
                  <div>
                    <span>info@shankarrealestate.com</span>
                  </div>
                </div>

                <div className="levbitz_buy_direct white">
                  <p>
                    Buy this all realestate deals at best price directly from
                    Shankar Real Estate{" "}
                  </p>

                  <div>
                    <form method="POST" ref={form} onSubmit={sendEmail}>
                      <div class="row">
                        <div class="input-field col l12 s12 hide">
                          <input
                            id="car"
                            type="text"
                            class="validate"
                            name="title"
                            value={title}
                          />
                          <label for="car"> Name</label>
                        </div>
                        <div class="input-field col l12 s12">
                          <input
                            id="first_name"
                            type="text"
                            class="validate"
                            name="first_name"
                          />
                          <label for="first_name"> Name</label>
                        </div>
                        <div class="input-field col l12 s12">
                          <input
                            id="contact"
                            type="text"
                            class="validate"
                            name="contact"
                          />
                          <label for="contact">Phone</label>
                        </div>
                        <div class="input-field col l12 s12">
                          <input
                            id="email"
                            type="text"
                            class="validate"
                            name="email"
                          />
                          <label for="email">Email</label>
                        </div>
                        <div>
                          <small>
                            Be assured! We never share your personal info
                            without asking you
                          </small>
                        </div>

                        <div>
                          <input
                            className="  darken-4 waves-effect waves-light white-text"
                            style={{
                              width: "100%",
                              border: "none",
                              background: "#27ae60",

                              padding: "10px",
                              borderRadius: "3px",
                            }}
                            type="submit"
                            value="Send Equiry"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default SingleLead;

const ImagePoping = ({ images, setShowPopup }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,

    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>;
    },
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
        }}
        className="img_popping_wrap"
      >
        <div className="container">
          <div>
            <div className="col l12">
              <p
                onClick={() => setShowPopup(false)}
                style={{
                  margin: "0px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  zIndex: "999",
                }}
                className="white-text right"
              >
                Close
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{
              margin: "0 auto !important",
              padding: "0px !important",
            }}
          >
            <div className="col l1"></div>
            <div className="col l10 s12 ">
              {images ? (
                <Slider {...settings}>
                  {images.map((imgUrl, index) => {
                    return (
                      <div key={index}>
                        <img
                          alt={imgUrl}
                          src={imgUrl}
                          // className="responsive-img"
                          style={{
                            // borderRadius: 10,
                            // width: 1200,
                            // objectFit: "contain",
                            // height: 400,
                            minHeight: "80vh",
                          }}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : null}
            </div>

            <div className="col l1"></div>
          </div>
        </div>
      </div>
    </>
  );
};
