import React, { useEffect, useState } from "react";
import "./QueryPage.css";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../components/Back/Back";
import P1 from "../../assets/images/property/p1.jpeg";

import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "../../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import { db } from "../../assets/lib/Firebase/Firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import Loading from "../../BusinessPage/Loading/Loading";

function QueryPage() {
  const { region, property } = useParams();

  const navigate = useNavigate();

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    // setAds(cars);
    setLoading(false);
  }, []);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,

      where("category", "==", property),
      where("regionUrl", "==", region)

      // //orderBy("createdAt", "desc"),
      // limit(8)
    );
    onSnapshot(q, (snapshoot) => {
      setResult(
        snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, []);

  console.log(result);
  return (
    <>
      <Navbar />
      <div>
        <Back
          name={`${property.replaceAll("_", " ")} in ${region.replaceAll(
            "_",
            " "
          )}`}
          title={`See  Our ${property.replaceAll(
            "_",
            " "
          )} in ${region.replaceAll("_", " ")}`}
        />
        <div className="container">
          <div className="row">
            {loading ? (
              <Loading />
            ) : result && result.length > 0 ? (
              result.map((item) => {
                return (
                  <SingleProperty
                    url={item.id}
                    bgImage={
                      item.data.images && item.data.images.length > 0
                        ? item.data.images[0]
                        : P1
                    }
                    text={item.data.title}
                    region={item.data.region}
                    acquisition={item.data.acquisition}
                  />
                );
              })
            ) : (
              <>
                <h2>No data</h2>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QueryPage;

const SingleProperty = ({ bgImage, text, region, acquisition, url }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Link to={`/lead/${url}`}>
        <div data-aos="fade-up" className="col l4 s12">
          <div className="xyz_levbitz">
            <div
              style={{
                backgroundImage: `url(${bgImage})`,
              }}
              className="single_property_wrap"
            >
              <div className="row">
                <div className="col l4 s4">
                  <div>
                    <span className="sales">For {acquisition}</span>
                  </div>
                </div>
                <div className="col l8  s4">
                  <div>
                    <span className="location center">{region}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_property_content">
              <h6 className="truncate">{text}</h6>

              <p>Price on call</p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
