import React, { useState, useEffect } from "react";
import { auth, db } from "../../assets/lib/Firebase/Firebase";

import { MdAutoDelete } from "react-icons/md";
import { Deactivate } from "../MyAds/MyAds";
import { Activate } from "../InactiveAds/InactiveAds";

import {
  collection,
  getDocs,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import "../MyAds/MyAds.css";
import { BiTimer } from "react-icons/bi";

import { Link, useNavigate } from "react-router-dom";

function LiveSearch({ user }) {
  const navigate = useNavigate();
  const [courses, setcourses] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [error, setError] = useState("");

  const checckerRef = collection(db, "ads");

  const q = query(checckerRef);

  useEffect(() => {
    setLoading(true);

    getDocs(q).then((snapshoot) => {
      setLoading(true);

      const term = snapshoot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setcourses(term);
      setLoading(false);
    });
    setLoading(false);
  }, []);

  // console.log(courses);

  return (
    <div className="container">
      <div className="row">
        <div className="col l4 s12">
          <div>
            <div className="row">
              <div className=" input-field input-outlined  ">
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  id="price"
                  type="text"
                />
                <label htmlFor="numberPlate"> Search by Product Id</label>
              </div>
            </div>

            <div>
              <div>
                {/*<img width={80} src={Certification} alt="" />*/}
                {courses
                  .filter((value) => {
                    if (searchTerm === "") {
                      return "";
                    } else {
                      return value.data.productId
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase());
                    }
                  })

                  .map((cour) => {
                    console.log(cour);
                    return (
                      <div
                        style={{
                          opacity: cour.data.active === true ? 1 : 0.8,
                          zIndex: 9999,
                        }}
                        key={cour.id}
                        className="col l12 s12   "
                      >
                        <div
                          style={{
                            border: "2px solid #c62828 ",
                            margin: "10px 10px",
                            borderRadius: 10,
                            height: 180,
                          }}
                          className="row "
                        >
                          <div className="col l3 s3 ">
                            <div
                              style={{
                                marginTop: "30%",
                              }}
                            >
                              <img
                                className="responsive-img circle"
                                src={cour.data.images[0]}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col l9 s8 ">
                            <div
                              style={{
                                marginBottom: "1rem",
                              }}
                            >
                              <h6
                                style={{
                                  fontSize: 14,
                                  color: "#004d40 ",
                                }}
                                className="my-ads__title truncate"
                              >
                                {cour.data.title}
                              </h6>
                              <small>{cour.data.productId}</small>
                            </div>

                            <div>
                              <div className="row">
                                <div className="col l2">
                                  <p>
                                    <p>
                                      <MdAutoDelete
                                        size={20}
                                        onClick={async () => {
                                          await addDoc(
                                            collection(db, "deletedAds"),
                                            {
                                              deletedAt: serverTimestamp(),
                                              id: cour.id,
                                              deleted: false,
                                            }
                                          );
                                          await deleteDoc(
                                            doc(checckerRef, cour.id)
                                          );
                                          await window.location.reload();
                                        }}
                                      />
                                    </p>
                                  </p>
                                </div>
                                <div className="col l10">
                                  <p>
                                    {cour.data.active ? (
                                      <Deactivate id={cour.id} />
                                    ) : (
                                      <Activate id={cour.id} />
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col l12">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: 20,
                                  }}
                                >
                                  <Link
                                    to={`/edit/${cour.id}`}
                                    className="left "
                                    style={{
                                      fontSize: 13,

                                      fontWeight: "bold",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Edit content
                                  </Link>
                                  <Link
                                    to={`/edit_img/${cour.id}`}
                                    className="right "
                                    style={{
                                      fontSize: 13,
                                      color: "coral",
                                      fontWeight: "bold",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Edit Images
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveSearch;
