import React from "react";
import { footer } from "../../../assets/data/Data";
import { Link } from "react-router-dom";
import "./footer.css";
import Logo from "../../../assets/images/logo.png";
import { location } from "../../../assets/data/Data";

import {
  BsFacebook,
  BsLinkedin,
  BsInstagram,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";
const Footer = () => {
  const upadate = new Date();
  const year = upadate.getFullYear();
  return (
    <>
      <section className="footerContact">
        <div className="container">
          <div className="row">
            <div className="col l7">
              <div className="text">
                <h6>Do You Have Questions ?</h6>
                <p>We'll help you to grow your career and growth.</p>
              </div>
            </div>
            <div className="col l5">
              <div className="levbitz_mobo_btn">
                <Link to="/contact" className="btn5">
                  Contact Us Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col l6 s12  center">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="" />
                </Link>

                <p>Follow Us On Social Media</p>
                <div className="nav_social_icons center ">
                  <span>
                    <BsFacebook
                      onClick={() =>
                        (window.location.href =
                          "http://https://www.facebook.com/devarajaproperties")
                      }
                      color="#fff"
                      size={20}
                    />
                  </span>

                  <span>
                    <FaTwitter
                      onClick={() =>
                        (window.location.href =
                          "https://twitter.com/devarajablr")
                      }
                      color="#fff"
                      size={20}
                    />
                  </span>

                  <span>
                    <BsInstagram
                      onClick={() =>
                        (window.location.href =
                          "https://www.instagram.com/invites/contact/?i=53kw7vh9frdb&utm_content=q7b019v")
                      }
                      color="#fff"
                      size={20}
                    />
                  </span>

                  {/*<span>
                <BsLinkedin color="#000" size={20} />
                        </span>*/}
                </div>

                {/*<h6>Do You Need Help With Anything?</h6>
                <p>
                  Receive updates, hot deals, tutorials, discounts sent straignt
                  in your inbox every month
  </p>*/}
              </div>
            </div>
            <div className="col l3 s4">
              {footer.map((val) => (
                <div className="box">
                  <h6>{val.title}</h6>
                  <ul
                    style={{
                      marginTop: 10,
                    }}
                  >
                    {location.map((item, index) => (
                      <Link to={item.path}>
                        <li>{item.name}</li>
                      </Link>
                    ))}

                    {val.text.map((items) => (
                      <li>
                        <Link className="grey-text" to={`${items.path}`}>
                          {items.list}
                        </Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="col l3 s8">
              <div>
                <h6>COMPANY</h6>
                <ul>
                  <li>info@shankarrealestate.com</li>
                  <li>+919845169306</li>
                  <li>
                    #21, 50ft. Main Road , NGEF Layout, Sanjay Nagar, Bengaluru-
                    560094
                  </li>

                  {/* <li>
                    <a
                      className="grey-text"
                      href="https://www.instagram.com/invites/contact/?i=53kw7vh9frdb&utm_content=q7b019v"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      className="grey-text"
                      href="https://www.facebook.com/devarajaproperties"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      className="grey-text"
                      href="https://twitter.com/devarajablr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                 </li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="legal">
        <span>
          Copyright ©2022- {year}™ Shankar Real estate. powered By {""}
          <a
            className="white-text"
            href="https://www.serpenstech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            serpens technology.
          </a>
        </span>
      </div>
    </>
  );
};

export default Footer;
